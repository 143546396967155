/*********/
/* NOTE: This file should only contain a minimal CSS reset. */
/*********/
/* Theme-specific globals like background color or font sizes can be added to the theme's `global` */
/* property which will be handled by Emotion's <Global /> component. All other styles should be */
/* scoped to a single component and colocalized with the JSX. */

@import-normalize;

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Set global typography defaults */
html,
body {
  background-color: white;
  color: #262626;
  font-family:
    "Atkinson",
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-size: 16px;
  font-size: var(--step-0);
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0);
}

/* Reset default margins and only use margin-bottom values */
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
blockquote,
fieldset,
dl,
dir,
menu {
  margin-top: 0;
  margin-bottom: 1rem;
  text-wrap: pretty;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GT Walsheim";
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

ol ul,
ol ol,
ul ul,
ul ol {
  margin-bottom: 0;
}
dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.1em 0.25em;
}
dl dt {
  font-weight: bold;
}
dl dd {
  margin-bottom: 0.75em;
  margin-left: 0;
}

small {
  display: inline-block;
  font-size: 0.7em;
}
abbr {
  cursor: help;
  text-decoration-color: currentColor;
  text-decoration-line: 1px;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 1px;
  white-space: nowrap;
}
[role="list"] {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
[role="list"] > *::before {
  content: "\200B";
  position: absolute;
}
[id] {
  scroll-margin-top: 5.5rem;
}
figure {
  margin: 0;
}
img {
  max-width: 100%;
}
fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
  border: 0;
}

fieldset legend {
  margin-bottom: 0.5rem;
}

*:focus-visible {
  outline: 2px solid #262626;
  outline-offset: 2px;
}
svg {
  color: currentColor;
}

@media (prefers-reduced-motion) {
  body #root * {
    transition-duration: 0.01s !important;
    transition-timing-function: ease !important;
  }
}
#root {
  width: 100%;
  max-width: 100%;
}
:root {
  --site-gutter: 1rem;
  --site-gap: 1rem;
  --slide-gap: 1rem;
  --form-padding: 1rem;
  /* Gap value for portfolio galleries grid in edit mode so it matches the masonry grid */
  --gallery-gap: 10px;
}
@media screen and (min-width: 600px) {
  :root {
    --site-gutter: 3.2rem;
    --slide-gap: 1.5rem;
    --form-padding: 2rem;
    --gallery-gap: 16px;
  }
}
@media screen and (min-width: 831px) {
  :root {
    --slide-gap: 2rem;
  }
}
@media screen and (min-width: 1140px) {
  :root {
    --site-gap: 2rem;
  }
}

/* Type Scale */
/* @link https://utopia.fyi/type/calculator?c=320,16,1.125,1140,18,1.2,6,4,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l */

:root {
  --fluid-min-width: 320;
  --fluid-max-width: 1140;

  --fluid-screen: 100vw;
  --fluid-bp: calc(
    (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
      (var(--fluid-max-width) - var(--fluid-min-width))
  );
}

@media screen and (min-width: 1140px) {
  :root {
    --fluid-screen: calc(var(--fluid-max-width) * 1px);
  }
}

:root {
  --f--4-min: 11.5;
  --f--4-max: 12;
  --step--4: calc(
    ((var(--f--4-min) / 16) * 1rem) + (var(--f--4-max) - var(--f--4-min)) *
      var(--fluid-bp)
  );

  --f--3-min: 12;
  --f--3-max: 12.5;
  --step--3: calc(
    ((var(--f--3-min) / 16) * 1rem) + (var(--f--3-max) - var(--f--3-min)) *
      var(--fluid-bp)
  );

  --f--2-min: 13;
  --f--2-max: 13.5;
  --step--2: calc(
    ((var(--f--2-min) / 16) * 1rem) + (var(--f--2-max) - var(--f--2-min)) *
      var(--fluid-bp)
  );

  --f--1-min: 14.22;
  --f--1-max: 15;
  --step--1: calc(
    ((var(--f--1-min) / 16) * 1rem) + (var(--f--1-max) - var(--f--1-min)) *
      var(--fluid-bp)
  );

  --f-0-min: 16;
  --f-0-max: 17;
  --step-0: calc(
    ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
      var(--fluid-bp)
  );

  --f-1-min: 18;
  --f-1-max: 21.6;
  --step-1: calc(
    ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
      var(--fluid-bp)
  );

  --f-2-min: 20.25;
  --f-2-max: 28.13;
  --step-2: calc(
    ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
      var(--fluid-bp)
  );

  --f-3-min: 22.78;
  --f-3-max: 35.16;
  --step-3: calc(
    ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
      var(--fluid-bp)
  );

  --f-4-min: 25.63;
  --f-4-max: 43.95;
  --step-4: calc(
    ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
      var(--fluid-bp)
  );

  --f-5-min: 28.83;
  --f-5-max: 60;
  --step-5: calc(
    ((var(--f-5-min) / 16) * 1rem) + (var(--f-5-max) - var(--f-5-min)) *
      var(--fluid-bp)
  );

  --f-6-min: 32.44;
  --f-6-max: 60;
  --step-6: calc(
    ((var(--f-6-min) / 16) * 1rem) + (var(--f-6-max) - var(--f-6-min)) *
      var(--fluid-bp)
  );
}

.ql-editor.ql-editor,
.ql-container.ql-container {
  font-family: inherit;
  line-height: 1.3;
}

.ql-disabled .ql-editor {
  overflow: hidden;
  padding: 0;
}

.ql-disabled .ql-editor p:has(br:only-child) {
  height: 0.5em;
  overflow: hidden;
}

/* Prevents the Facebook pixel from nudgng down the site header */
body > img:first-child {
  position: absolute;
}
