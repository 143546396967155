/* NOTE: Before changing styles; read *DANGER* note in component file */
.GlobalErrorBoundary {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  padding: 1rem;
}

.GlobalErrorBoundary img {
  margin-bottom: 3rem;
  width: 10%;
  min-width: 150;
}

/* NOTE: Before changing styles; read *DANGER* note in component file */
.GlobalErrorBoundary h1 {
  margin-bottom: 1rem;
  line-height: 1;
}

.GlobalErrorBoundary p {
  margin-bottom: 2rem;
}

/* NOTE: Before changing styles; read *DANGER* note in component file */
.GlobalErrorBoundary button {
  align-items: center;
  background: #262626;
  border: 2px solid;
  border-radius: 9999px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "GT Walsheim";
  font-weight: bold;
  gap: 6;
  justify-content: center;
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  line-height: 1.2;
  padding: 0.45em 1.2em 0.4em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
/* NOTE: Before changing styles; read *DANGER* note in component file */
.GlobalErrorBoundary button:focus,
.GlobalErrorBoundary button:hover {
  background: #fff;
  color: #262626;
}

.GlobalErrorBoundary pre {
  background: #ddd;
  font-size: 1rem;
  margin-top: 2.5rem;
  padding: 1rem;
  max-width: 100%;
  overflow: auto;
}
/* NOTE: Before changing styles; read *DANGER* note in component file */
